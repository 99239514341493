.site-layout-content {
    min-height: 280px;
    padding: 0px;
    margin-top: 36px;
    background: #fff;
    max-width:1440px;
    margin-left:auto;
    margin-right:auto;

}

.ant-layout{
    background:#fff!important;
}
.ant-layout-header .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;

}

.loginSpin {
    color: rgba(75,77,79,0.39);
    position: fixed!important;
    display: flex!important;
    min-width: 100%;
    /* z-index: 999; */
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    vertical-align: middle;
    margin: 0;
    left: 0!important;
    top: 0!important;
}

.manyosSpin{
    top:0px!important;
    left:0px!important;
    position:relative!important;
    font-size: 200px!important;

}

.ant-card-head{
    border-bottom:none;
}

.sc-error-alert{
    text-align: left;
}

.browse-no-pic{
    height:170px;
    width:300px;
    background-color:#f0f2f5;
    text-align: center;
    vertical-align: middle;
    line-height: 170px;
}

.block-end>span{
    text-align: center;
    color:#cccbcb;
    display: table;
    padding: 0px 18px;
    margin-left: auto;
    position: relative;
    top: 17px;
    background-color: #fff;
    margin-right: auto;
}
.block-end>Button{
    /* position: relative; */
    /* right: 32px; */
    top: 15px;
    margin-left: 26px;
    /* margin-right: 0px; */
    float: right;
    background-color: #fff;
}
.block-end{
    border-bottom: 1px dashed;
    border-color: #cccbcb;
    margin-bottom: 40px;
    height: 30px;
    width: 100%;
}

.divSpacer{
    height:12px;
}

.floating-div{
    float:left;
}

.ant-col-12{
    width: 50%!important;
}
.ant-col-24{
    width: 100%!important;
}
.ant-col-6{
    width: 25%!important;
}
.ant-col-18{
    width: 75%!important;
}

.element{
    margin-left:5px;
    margin-right:5px;
}

.builder-props-label{
    width: 100px;
    margin-right:8px;
    float:left;
    margin-bottom: 8px;
}
.builder-props-field{
    width: 320px;
    float:left;
    margin-bottom: 8px;
}
.builder-props-switch{

    float:left;
    margin-bottom: 8px;
    margin-right: 100px;
}
.builder-del-element{

    position:absolute;
    right:-15px;
    top:-15px;
}


.builder-field{
    width: 100%;
    position:relative;

    margin: 8px;
    min-height:30px;
    text-align: center;
    vertical-align: middle;
    border:1px dashed gray;
    line-height: 30px;

}

.builder-field:hover {

    border: 1px dashed lightblue;
    cursor: pointer
}

.steps-content {
    height: calc(100% - 100px);
    padding:20px;

    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
}

.steps-action {
    margin-top: 24px;
}

.ant-upload-select-picture-card {
    width: 300px!important;
    height: 170px!important;
}
.ant-upload-list-picture-card-container{
    width: 300px!important;
    height: 170px!important;
}
.ant-upload-picture-card-wrapper{
    width:300px!important;
}

.ant-tabs {
    float:left;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
    object-fit: cover
}

.block-bg0{
    background-color: white;

    display: flow-root;
}
.block-bg1{
    background-color: #80808014;

    display: flow-root;
}
.header-userName{
    float:right;
    color:rgba(255, 255, 255, 0.65);
}

.submitButton{
    position: fixed;


}
@media (max-width: 767px) {
    .submitButton{
        position: relative;
    }

}
@media (max-width: 500px) {
    .submitButton{
        position: relative;
    }
}


.radioButtonAsLine > .ant-radio-wrapper {
    width: 100%;
}
